((_window, _document) => {
  const FIXED_BAR_HEIGHT = 50;
  const PROGRESS_BAR_HEIGHT = 3;
  // message actions
  const CLOSE_MESSAGE_CHANNEL = 'JFREPORT:CLOSE_MESSAGE_CHANNEL';
  const PRESENTATION_FRAME_IS_READY = 'JFREPORT:PRESENTATION_FRAME_IS_READY';
  const SET_FRAME_LAYOUT = 'JFREPORT:SET_FRAME_LAYOUT';

  // TODO :: Loading state
  const embedContainer = _document.querySelector('.jotform-embed');
  const containerStyles = _window.getComputedStyle(embedContainer.parentElement);
  const containerWidth = containerStyles.width.replace('px', '');
  const containerHeight = containerStyles.height.replace('px', '');

  const presentationID = embedContainer.getAttribute('data-id');

  const iframe = _document.createElement('iframe');

  const iframeSource = embedContainer.hasAttribute('data-iframesource') ? embedContainer.getAttribute('data-iframesource') : 'www.jotform.com';

  // set attribute for iframe
  iframe.src = `https://${iframeSource}/report/${presentationID}?embedMode=js`;
  iframe.scrolling = 'no';
  iframe.frameborder = 0;
  iframe.style.border = 'none';
  iframe.style.borderRadius = '6px';
  iframe.setAttribute('allowFullScreen', '');

  embedContainer.appendChild(iframe);

  // get reportSettings from iframe message
  const receivePostMessage = ({
    data: {
      payload,
      type
    }
  }) => {
    if (!type || type.split(':')[0] !== 'JFREPORT') {
      return;
    }

    switch (type) {
      case SET_FRAME_LAYOUT: {
        try {
          const { reportLayoutHeight, reportLayoutWidth, showProgressBar } = payload;
          const width = parseInt(reportLayoutWidth, 10);
          const height = parseInt(reportLayoutHeight, 10);
          const reportAspectRatio = width / height;

          const paddingValue = showProgressBar ? FIXED_BAR_HEIGHT + PROGRESS_BAR_HEIGHT : FIXED_BAR_HEIGHT;

          if (reportAspectRatio >= 1 || containerHeight < 200) {
            iframe.width = containerWidth > 320 ? containerWidth : 320;
            iframe.height = (iframe.width / reportAspectRatio) + paddingValue;
          } else {
            // TODO :: maybe set minimum height ratiod to mininmum width (320px)
            iframe.width = containerHeight * reportAspectRatio > 320 ? containerHeight * reportAspectRatio : 320;
            iframe.height = parseInt(containerHeight, 10) + paddingValue;
          }

          iframe.contentWindow.postMessage({
            /**
             * Since we are not listening for any further action
             * this message will remove the message listener
             * located in the jotform/presentations -> Found.js
             */
            type: PRESENTATION_FRAME_IS_READY
          }, '*');
          iframe.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.2)';
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error in post messaging with report frame', error);
        }
        break;
      }
      case CLOSE_MESSAGE_CHANNEL: {
        _window.removeEventListener('message', receivePostMessage, false);
        break;
      }
      default: //
    }
  };

  _window.addEventListener('message', receivePostMessage, false);
})(window, document);
